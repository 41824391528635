<template>
  <div class="layoutContent">
    <div>
      <home-title
        :description="SettingMsg.description"
        :showpic="
          getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
        "
      />
      <homeItem v-if="description">
        <div class="d-flex padding-5">
          <div class="wapHide">
            <img
              src="@/assets/img/text-icon2.png"
              style="width: 250px; margin-right: 30px"
              alt="news"
              v-if="
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'showPic',
                  'vIf'
                )
              "
            />
          </div>
          <div>
            <div style="font-size: 24px; font-weight: bold">
              {{ $g("positionDescription") }}
            </div>
            <div v-html="description" />
          </div>
        </div>
      </homeItem>
      <homeItem
        class="boxMargin"
        :item-title="$g('location')"
        v-if="mapShowData.length > 0 && unit != 2 && unit != 16 && unit != 1"
      >
        <div class="locationBox">
          <div class="picBox" ref="picBox">
            <div
              class="picList"
              v-for="(item, index) in mapShowData"
              :key="index"
            >
              <div class="picList_title">
                <div class="flex-shrink-0 mr-3">
                  <img :src="item.active ? signBlue : signRed" alt="maoIcon" />
                </div>
                <div style="flex: 1">
                  <div
                    class="featuredFont font-weight-bold d-flex justify-content-between"
                  >
                    {{ item.name }}
                    <div>
                      <div>
                        <a
                          href="javascript:void(0)"
                          @click="viewMoreInformationClick(item)"
                          v-permission="
                            PW_Worker_Portal_Code.WorkAssignments
                              .ViewMoreInformation
                          "
                          v-if="item.hasViewMoreInformation"
                        >
                          {{ $g("viewMoreInformation") }}
                        </a>
                      </div>
                      <div>
                        <a
                          href="javascript:void(0)"
                          @click="onLocation(item.faciliyId)"
                          v-permission="
                            PW_Worker_Portal_Code.WorkAssignments
                              .ViewFacilitiesAccessibilityPicture
                          "
                          v-if="canAssignedWorkersSeeFacility"
                        >
                          {{ $g("viewAccessibilityInformation") }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    {{
                      item.eleFaciliyRooms &&
                      item.eleFaciliyRooms.map((e) => e.eleRoomName).join(",")
                    }}
                  </div>
                  <div>{{ item.address }}</div>
                  <div
                    v-if="
                      item.eleFaciliyRooms &&
                      item.eleFaciliyRooms.some((e) => e.additionalInformation)
                    "
                  >
                    {{
                      item.eleFaciliyRooms &&
                      item.eleFaciliyRooms
                        .map((e) => e.additionalInformation)
                        .join(",")
                    }}
                  </div>
                  <div v-else>
                    {{ item.additionalInformation }}
                  </div>
                </div>
              </div>

              <div class="box" v-if="item.photoLists.length > 0">
                <b-icon
                  v-if="item.photoLists.length > 0"
                  icon="chevron-left"
                  font-scale="2"
                  :class="[
                    'icons1',
                    'icons',
                    { hiddenPic: item.leftBtnShow },
                    'flex-shrink-0',
                    'mr-2',
                  ]"
                  @click="left(index, item)"
                />

                <div class="imgbox">
                  <ul
                    ref="ul"
                    :style="{ transform: `translateX(${slidenum[index]}px)` }"
                  >
                    <li
                      ref="pic"
                      v-for="(itemOne, indexOne) of item.photoLists"
                      class="slide"
                      :key="indexOne"
                      @click="tip(itemOne.base64String)"
                    >
                      <img
                        :src="itemOne.base64String"
                        alt="error"
                        crossorigin="anonymous"
                        v-b-tooltip="{
                          trigger: 'hover',
                          title: itemOne.description,
                          boundary: 'window',
                        }"
                      />
                    </li>
                  </ul>
                </div>
                <b-icon
                  v-if="item.photoLists.length > 1"
                  icon="chevron-right"
                  font-scale="2"
                  :class="[
                    'icons1',
                    'icons',
                    { hiddenPic: item.rightBtnShow },
                    'flex-shrink-0',
                    'ml-2',
                  ]"
                  @click="right(index, item)"
                />
              </div>
            </div>
          </div>
          <div class="map">
            <k-map
              ref="map"
              :map-init="onMapInit"
              v-if="mapShowData.length > 0"
            />
          </div>
        </div>
      </homeItem>
      <homeItem
        class="boxMargin"
        :item-title="$g('otherStaff')"
        v-if="stafflist.length > 0"
      >
        <b-table bordered :fields="fields" :items="stafflist" class="wapHide">
          <template #cell(positionList)="row">
            <div v-for="(i, j) in row.value" :key="j">
              {{ i.eleJobTitle }}
            </div>
          </template>
          <template #cell(scheduleList)="row">
            <div v-for="(i, j) in row.value" :key="j">
              {{ i.workDateStr }}
            </div>
          </template>
          <template #cell(showComments)="row">
            <k-link v-if="row.item.showComments" @click="showComments(row.item)"
              >{{ $g("view") }}
            </k-link>
          </template>
          <template #cell(contacted)="row">
            <k-form-checkbox
              v-model="row.item.contacted"
              @change="(val) => contactChange(val, row.item)"
            />
          </template>
        </b-table>
        <otherStaffWap :fields="fields" :items="stafflist" />
      </homeItem>
    </div>
    <k-popwindow
      id="modal-comments"
      :before-close="null"
      :check-selected="false"
      :hide-ok="true"
      :hide-footer="true"
      style="width: 30%"
    >
      <comments :refId="selectId"></comments>
    </k-popwindow>
    <k-popwindow
      id="modal-pic"
      :before-close="null"
      :check-selected="false"
      :hide-ok="true"
      :hide-footer="true"
      style="width: 30%"
    >
      <img
        :src="imgsrc"
        alt=""
        style="width: 100%; height: 600px; object-fit: fill"
      />
    </k-popwindow>
    <k-popwindow
      id="modal-viewMoreInformation"
      :before-close="null"
      :check-selected="false"
      :hide-ok="true"
      :hide-footer="true"
      :title="$g('viewMoreInformation')"
    >
      <viewMoreInformationPopwindow
        :election-id="viewMoreInformationPopwindowData.electionId"
        :ele-facility-id="viewMoreInformationPopwindowData.eleFacilityId"
      />
    </k-popwindow>
    <k-popwindow
      id="modal-photoList"
      :hide-ok="true"
      :cancel-title="$g('popwindow.close')"
      :title="$g('facilitiesAccessibilityInformation')"
    >
      <PhotoList :ele-facility-id="popEleFacilityId" />
    </k-popwindow>
  </div>
</template>

<script>
import homeItem from "./components/home-item.vue";
import {
  getDetailDescription,
  getOtherStaffsList,
  getElefacility,
  getViewMoreInformationShow,
  setContactStatus,
  getCanAssignedWorkersSeeFacility,
} from "@/api/workAssignments";
import { getPortalPageSetting } from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import {
  g,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import otherStaffWap from "./components/other-staff-wap.vue";
import signRed from "../../assets/image/signRed.png";
import signBlue from "../../assets/image/signBlue.png";
import HomeTitle from "@/views/components/homeTitle";
import viewMoreInformationPopwindow from "./components/viewMoreInformationPopwindow.vue";
import { PW_Worker_Portal_Code } from "@/router/permissionCode";
import comments from "./components/comments.vue";
import PhotoList from "@/views/workAssignments/components/photoList";

export default {
  components: {
    homeItem,
    otherStaffWap,
    HomeTitle,
    viewMoreInformationPopwindow,
    comments,
    PhotoList,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );

    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      selectId: "",
      PW_Worker_Portal_Code,
      SettingMsg: {},
      description: "",
      stafflist: [],
      fields: [
        {
          key: "fullName",
          label: g("worker'sName"),
        },
        {
          key: "positionList",
          label: g("position"),
        },
        {
          key: "scheduleList",
          label: g("schedule"),
        },
        {
          key: "phone",
          label: g("cellPhone"),
        },
        {
          key: "email",
          label: g("staffEmail"),
        },
        {
          key: "showComments",
          label: g("comments"),
        },
        {
          key: "contacted",
          label: g("contacted"),
        },
      ],
      requestData: {
        electionId: this.$route.query.electionId,
        eleJobId: this.$route.query.eleJobId,
      },
      slide_array: [],
      mapShowData: [],
      slidenum: [],
      now_index: [],
      picBoxWidth: "",
      imgsrc: "",
      unit: this.$route.query.type,
      rightBtnShow: false,
      leftBtnShow: true,
      signRed: signRed,
      signBlue: signBlue,
      hasViewMoreInformation: false,
      viewMoreInformationPopwindowData: {
        electionId: this.$route.query.electionId,
        eleFacilityId: null,
      },
      mapData: null,
      popEleFacilityId: null,
      canAssignedWorkersSeeFacility: false,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    onLocation(id) {
      this.popEleFacilityId = id;
      this.$bvModal.show("modal-photoList");
    },
    contactChange(val, item) {
      let params = {
        electionId: this.$route.query.electionId,
        pollId: item.pollId,
        contacted: val,
      };
      setContactStatus(params);
    },
    showComments(item) {
      this.selectId = item.pollId;
      this.$bvModal.show("modal-comments");
    },
    getDetail() {
      getCanAssignedWorkersSeeFacility(this.requestData.eleJobId).then(
        (resp) => {
          this.canAssignedWorkersSeeFacility = resp.data?.info == 2;
        }
      );
      getPortalPageSetting(pageNameEnum.WorkAssignmentDetail).then((res) => {
        this.SettingMsg = res.data;
      });
      getDetailDescription({
        ...this.requestData,
      }).then((res) => {
        this.description = res.data;
      });
      getElefacility({
        ...this.requestData,
        elePollingPlaceId: this.$route.query.elePollingPlaceId,
      }).then((res) => {
        this.mapShowData = res.data.faciliyList;
        this.mapShowData.forEach((element) => {
          if (element.eleFaciliyRooms?.length) {
            let picLists = [];
            element.eleFaciliyRooms.forEach((ele) => {
              if (ele.eleRoomPhotoList.length) {
                picLists.push(...ele.eleRoomPhotoList);
              }
            });
            if (picLists.length) {
              element.photoLists = picLists;
            }
          }
        });
        this.mapData = res.data;
        let faciliyIdArray = this.mapShowData.map((c) => {
          return c.faciliyId;
        });
        this.initViewMoreInformationShow(faciliyIdArray);
      });
      getOtherStaffsList({
        ...this.requestData,
        elePollingPlaceId: this.$route.query.elePollingPlaceId,
      }).then((res) => {
        this.stafflist = res.data;
      });
    },
    left(index, item) {
      if (this.now_index[index] != 0) {
        this.now_index[index]--;
        this.slidenum[index] = "-" + 170 * this.now_index[index];
        this.$forceUpdate();
      }
      if (this.now_index[index] == 0) {
        item.leftBtnShow = true;
        item.rightBtnShow = false;
      } else {
        item.rightBtnShow = false;
        item.leftBtnShow = false;
      }
    },
    right(index, item) {
      if (this.now_index[index] == undefined) {
        this.now_index[index] = 0;
      }
      if (this.now_index[index] != item.photoLists.length - 1) {
        this.now_index[index]++;
        this.slidenum[index] = "-" + 170 * this.now_index[index];
        this.$forceUpdate();
      }
      if (this.now_index[index] == item.photoLists.length - 1) {
        item.leftBtnShow = false;
        item.rightBtnShow = true;
      } else {
        item.leftBtnShow = false;
        item.rightBtnShow = false;
      }
    },
    tip(src) {
      this.imgsrc = src;
      this.$bvModal.show("modal-pic");
    },
    onMapInit(map) {
      this.mapShowData.forEach((element) => {
        console.log(element);
        if (element.photoLists?.length > 0) {
          element.leftBtnShow = true;
        }
        element.rightBtnShow = false;
        this.slidenum.push(0);

        this.now_index.push(0);
      });
      if (this.mapShowData.length == 1) {
        this.mapData.mapPoint.center.latitude = this.mapShowData[0].latitude;
        this.mapData.mapPoint.center.longitude = this.mapShowData[0].longitude;
      }
      this.$refs.map.addGeoJson(this.mapData.mapPoint);

      let infowindow = new google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(0, -20), // offset for icon
      });
      let me = this;
      map.data.setStyle({
        icon: signRed,
      });
      let marker = new google.maps.Marker({ icon: signBlue });
      map.data.addListener("click", function (event) {
        infowindow.setContent(event.feature.getProperty("title"));
        infowindow.setPosition(event.feature.getGeometry().get());
        infowindow.open(me.$refs.map.getMap());
        marker.setPosition(event.feature.getGeometry().get());
        marker.setMap(me.$refs.map.getMap());
        let id = event.feature.h.id;
        me.mapShowData.forEach((element) => {
          if (element.faciliyId == id) {
            element.active = true;
          } else {
            element.active = false;
          }
        });
        me.$forceUpdate();
      });
      if (this.mapShowData.length == 1) {
        console.log(123);
        setTimeout(() => {
          map.setZoom(
            this.getConfigVal(
              this.curPageConfigAndDefaultPageConfig,
              "MapZoom",
              "zoom"
            )
          );
        }, 500);
      }
    },
    initViewMoreInformationShow(faciliyIdArray) {
      getViewMoreInformationShow({
        eleJobId: this.$route.query.eleJobId,
        electionId: this.$route.query.electionId ?? "",
        faciliyIdArray: faciliyIdArray,
      }).then((result) => {
        let items = result.data;
        this.mapShowData.forEach((c) => {
          this.$set(c, "hasViewMoreInformation", items[c.faciliyId]);
        });
      });
    },
    viewMoreInformationClick(item) {
      this.viewMoreInformationPopwindowData.eleFacilityId = item.faciliyId;
      this.$bvModal.show("modal-viewMoreInformation");
    },
  },
};
</script>

<style lang="scss" scoped>
.layoutContent {
  .headerTitle {
    margin-bottom: 25px;
  }

  .boxMargin {
    .locationBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .picBox {
        width: calc(50% - 10px);

        .picList {
          width: 100%;
          margin-bottom: 20px;

          .picList_title {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
          }

          .box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .hiddenPic {
              visibility: hidden;
            }

            .imgbox {
              width: 95%;
              margin: 0;
              overflow: hidden;

              ul {
                width: 5800px;
                transition: 1s;
                padding-left: 0;
                //display: flex;
                li {
                  cursor: pointer;
                  width: 160px;
                  height: 160px;
                  overflow: hidden;
                  float: left;
                  margin-right: 10px;
                  position: relative;

                  img {
                    max-height: 100%;
                    width: 100%;
                    height: 100%;
                  }

                  .zhezhao {
                    width: 100%;
                    height: 100%;
                    background: rgba(155, 155, 155, 0.7);
                    position: absolute;
                    z-index: 11;
                    top: 0;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }

      .map {
        width: calc(50% - 10px);
      }
    }
  }
}

@media (max-width: 991px) {
  .locationBox {
    flex-direction: column-reverse;

    .picBox {
      width: 100% !important;
    }

    .map {
      height: 400px;
      width: 100% !important;
      margin-bottom: 30px;
    }
  }
}
</style>
